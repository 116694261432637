import {
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BaseColor from "../../Config/colors";
import CHeader from "../../Components/Header";
import styles from "./styles";
import { customerSelectionData } from "../../Config/staticData";
import Carousel from "react-material-ui-carousel";
import theme, { FontFamily } from "../../Config/theme";
import CButton from "../../Components/CButton";
import { isMobile, isTablet, isIOS } from "react-device-detect";
import { IoCheckmarkOutline, IoCloseOutline } from "react-icons/io5";
import CloseIcon from "@mui/icons-material/Close";
import CModal from "../../Components/CModal";
import CInput from "../../Components/CInput";
import _ from "lodash";
import CIcon from "../../Components/CIcon";
import Files from "../../Config/Files";
import { CiSearch } from "react-icons/ci";
import CSelect from "../../CSelect";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { socket } from "../../Context/ContextSocket";
import { useDispatch, useSelector } from "react-redux";
import { Setting } from "../../Utils/Setting";
import { getAPIProgressData } from "../../Utils/APIHelper";
import { motion } from "framer-motion";
import authActions from "../../Redux/reducers/auth/actions";

const ClubDetails = () => {
  // media queries
  const classes = styles();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  // Redux
  const userData = useSelector((state) => state.auth.userData);
  const chatMessage = useSelector((state) => state.auth.chatMessage);
  const { setCTab } = authActions;
  const dispatch = useDispatch();

  // Member list
  const [allMembersData, setAllMembersData] = useState();
  const [membersData, setMembersData] = useState([]);

  // get id from url params
  const { id } = useParams();

  // profile data
  const [profileData, setProfileData] = useState();

  const navigate = useNavigate();

  // read more
  const [readMore, setReadMore] = useState(true);

  // page loader
  const [pageLoader, setPageLoader] = useState(false);

  //profile details modal
  const [openProfileDetailsModal, setOpenProfileDetailsModal] = useState(false);

  // clubData
  const [clubData, setClubData] = useState({});

  // chat btn loader
  const [chatBtnLoader, setChatBtnLoader] = useState(false);

  // send chat
  const [sendChat, setSendChat] = useState({
    id: 1,
    name: "All",
    title: "All",
  });
  const [message, setMessage] = useState("");

  // chat with member modal
  const [openChatWithMemberModal, setOpenChatWithMemberModal] = useState(false);

  // search
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState();

  //image carousal active image
  const [activeImg, setActiveImg] = useState(0);

  // pay btn disable
  const [sendBtnDisable, setSendBtnDisable] = useState(false);

  // chat btn disable
  const [chatBtnDisable, setChatBtnDisable] = useState(false);

  useEffect(() => {
    const onlyNumberRegex = /^[0-9]*$/;
    if (onlyNumberRegex?.test(id)) {
      getClubDetails(id, true);
    }
  }, []);

  // get club details api call
  const getClubDetails = async (id, pageLoad) => {
    if (pageLoad) {
      setPageLoader(true);
    }
    let endPoints = Setting.endpoints.clubDetails;

    try {
      const resp = await getAPIProgressData(
        endPoints,
        "POST",
        { club_id: id },
        true
      );
      if (resp?.success) {
        if (
          !_.isEmpty(resp?.data) &&
          !_.isUndefined(resp?.data) &&
          !_.isNull(resp?.data)
        ) {
          setClubData(resp?.data);
          setAllMembersData(resp?.data?.members);
          setFilteredData(resp?.data?.members);
          setMembersData(resp?.data?.members);
        }

        if (pageLoad) {
          setPageLoader(false);
        }
      } else {
        toast(resp?.message, {
          type: "error",
        });
        if (pageLoad) {
          setPageLoader(false);
        }
      }
    } catch (error) {
      toast(error.message, { type: "error" });
      if (pageLoad) {
        setPageLoader(false);
      }
    }
  };

  // send notification
  const sendNotification = async (user, msg) => {
    if (Number(user?.is_do_not_disturb) !== 1) {
      let endPoints = Setting.endpoints.sendNotification;

      const data = {
        member_id: user?.user_id,
        message: msg,
        title: `${userData?.nick_name} sent you a chat message`,
      };

      try {
        const resp = await getAPIProgressData(endPoints, "POST", data, true);
        console.log("resp======>>>>>", resp);
      } catch (error) {
        toast("Something went wrong", { type: "error" });
      }
    }
  };

  // carousel images
  const PostImageList = () => {
    const data =
      !_.isEmpty(clubData?.images) && _.isArray(clubData?.images)
        ? clubData?.images.filter((data) => {
            return data?.url;
          })
        : [];
    const postImagesList = data;

    return (
      <>
        {postImagesList?.map((item, index) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flex: postImagesList.length > 2 && 1,
              }}
              onClick={() => setActiveImg(index)}
            >
              <img
                alt="thumbnail"
                src={item?.url}
                draggable={false}
                className={classes.smallImgs}
                style={{
                  border:
                    activeImg === index ? `3px solid ${BaseColor.primary}` : "",
                  marginRight: 10,
                  flex: 1,
                }}
              />
            </div>
          );
        })}
      </>
    );
  };

  // carousel images
  const CarouselContainer = () => {
    const data = clubData?.images;

    const postList = data;

    return (
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <div>
          <Carousel
            navButtonsAlwaysInvisible={true}
            animation="slide"
            autoPlay={false}
            indicators={false}
            index={activeImg}
            sx={{
              borderRadius: "10px",
              minHeight: sm ? "300px" : md && "400px",
              height: !sm && !md && "calc(100vh - 320px)",
            }}
            onChange={(index) => {
              setActiveImg(index);
            }}
            className={classes.car}
          >
            {!_.isEmpty(postList) &&
              _.isArray(postList) &&
              postList.map((item, index) => {
                const imageUrl =
                  !_.isEmpty(item?.url) &&
                  !_.isNull(item?.url) &&
                  !_.isUndefined(item?.url)
                    ? item?.url
                    : "";

                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: sm ? "30%" : "00%",
                    }}
                  >
                    <img
                      alt={"carousle_img"}
                      src={imageUrl}
                      draggable={false}
                      className={`${classes.carImg} ${classes.carImageZoom}`}
                    />
                  </div>
                );
              })}
          </Carousel>
        </div>

        <Grid
          item
          style={{
            // width: "100%",
            padding: "20px 0px 5px 0px",
            width: !lg ? "41vw" : md ? "90vw" : "83vw",
          }}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid
            item
            className={classes.scrollBar}
            id="scrollImg"
            style={{
              width: "100%",
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              overflowX: "auto",
              paddingBottom: "10px",
            }}
          >
            <PostImageList />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  // chat with members
  function handleChatWithMember() {
    setChatBtnLoader(true);
    socket.connect();
    let brodCastId;
    let brodcastName;

    if (sendChat.name === "All") {
      const newData = allMembersData?.map((item) => item.user_id);
      brodCastId = newData && newData?.filter((id) => id != userData?.id);
      brodcastName =
        allMembersData &&
        allMembersData.map((item) => {
          return `${item.firstname} ${item.lastname}`;
        });
    } else {
      brodCastId =
        allMembersData &&
        allMembersData
          .filter(
            (item) =>
              item.hasOwnProperty("isSelected") && item.isSelected === true
          )
          .map((item) => item.user_id);
      brodcastName =
        allMembersData &&
        allMembersData
          .filter(
            (item) =>
              item.hasOwnProperty("isSelected") && item.isSelected === true
          )
          .map((item) => {
            return `${item.firstname} ${item.lastname}`;
          });
    }
    const broadcastRequest = {
      s_id: userData?.id,
      r_id: brodCastId,
      trip_id: id || "",
      trip_type: "s",
      // con_name: brodcastName,
      broadcast: true,
    };

    const requestData = {
      s_id: userData.id,
      r_id: [profileData?.user_id],
      trip_id: id || "",
      trip_type: "s",
      con_name: `${profileData?.firstname} ${profileData?.lastname}`,
    };
    socket.emit(
      "create_conversation",
      openProfileDetailsModal ? requestData : broadcastRequest,
      (response) => {
        if (!response.status && !openProfileDetailsModal) {
          toast("Message Sent", {
            type: "info",
          });
          response?.map((item) => {
            if (!_.isEmpty(item.data) && !openProfileDetailsModal) {
              const requestdata = {
                file_type: "text",
                s_id: userData?.id,
                c_id: item?.data?.conv_id,
                msg: message,
              };
              socket.emit("send_message", requestdata, (response) => {});
              sendNotification(profileData, message);
            }
            dispatch(setCTab("personal"));
            setTimeout(() => {
              navigate(`/chat?single=${true}`);
            }, 1000);
          });
        } else {
          if (!openProfileDetailsModal) {
            response?.map((item) => {
              if (!_.isEmpty(item.data)) {
                const requestdata = {
                  file_type: "text",
                  s_id: userData?.id,
                  c_id: item?.data?.conv_id,
                  msg: message,
                };
                socket.emit("send_message", requestdata, (response) => {});
                sendNotification(profileData, message);
              }
              setTimeout(() => {
                navigate(`/chat`);
              }, 1000);
            });
          } else {
            const requestdata = {
              file_type: "text",
              s_id: userData?.id,
              c_id: response?.data?.conv_id,
              msg: chatMessage?.singleChat,
            };
            socket.emit("send_message", requestdata, (response) => {
              if (!response?.status) {
                toast(response?.message, {
                  type: "info",
                });
              }
            });
            sendNotification(profileData, chatMessage?.singleChat);
            dispatch(setCTab("personal"));
            setTimeout(() => {
              navigate(`/chat?c_id=${response?.data?.conv_id}`);
            }, 1000);
          }
        }
      }
    );
    setChatBtnLoader(false);
  }

  return (
    <Grid
      container
      style={{
        backgroundColor: BaseColor.white,
        overflowY: "auto",
        height: isMobile && isIOS ? "calc(100vh - 100px)" : "100vh",
      }}
    >
      <CHeader
        title={"Details"}
        backButtonClick={() => {
          navigate(-1);
        }}
      />
      {pageLoader ? (
        <Grid
          container
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          style={{
            height:
              md || isMobile || isTablet
                ? "calc(100vh - 70px)"
                : "calc(100vh - 90px)",
          }}
        >
          <CircularProgress size={40} color="primary" />
        </Grid>
      ) : (
        <>
          <Grid item xs={0.5} sm={0.5} md={1} lg={1} />
          <Grid
            item
            xs={11}
            sm={11}
            md={10}
            lg={10}
            style={{
              minHeight: "calc(100vh - 90px)",
            }}
          >
            <Grid container>
              {!lg && (
                <Grid item xs={12} display={"flex"}>
                  <CIcon
                    src={Files.svgIcons.BackArrowIcon}
                    size={30}
                    style={{
                      marginLeft: "-4px",
                      cursor: !isMobile && !isTablet && "pointer",
                      marginTop: "20px",
                    }}
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                  <Typography className={classes.title}>
                    {clubData?.name}
                  </Typography>
                </Grid>
              )}

              <Grid item xs={12} sm={12} md={12} lg={6} mt={"10px"} border={""}>
                <Grid container>
                  <Grid item xs={12}>
                    {CarouselContainer()}
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                mt={!lg && "10px"}
                pl={!lg && "30px"}
                style={{
                  height: !lg && "calc(100vh - 180px)",
                }}
              >
                {lg && (
                  <Grid item xs={12} display={"flex"}>
                    <Typography className={classes.title}>
                      {clubData?.name}
                    </Typography>
                  </Grid>
                )}
                <motion.Grid
                  container
                  rowGap={2}
                  style={{
                    height: !lg && "calc(100% - 20px)",
                    overflowY: !lg && "auto",
                    paddingRight: "10px",
                  }}
                  className={classes.scrollBar}
                >
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <Typography
                        className={classes.eventDescription}
                        style={{ whiteSpace: "pre-line" }}
                      >
                        {readMore &&
                        !_.isEmpty(clubData?.about_club) &&
                        !_.isNull(clubData?.about_club) &&
                        clubData?.about_club?.length > 300
                          ? clubData?.about_club.slice(0, 300)
                          : clubData?.about_club}
                        <span
                          style={{
                            fontFamily: FontFamily.SemiBold,
                            cursor: !isMobile && !isTablet && "pointer",
                            fontSize: 14,
                            color: BaseColor.primary,
                          }}
                          onClick={() => setReadMore(!readMore)}
                        >
                          {!_.isEmpty(clubData?.about_club) &&
                          !_.isNull(clubData?.about_club) &&
                          clubData?.about_club.length > 300
                            ? readMore && clubData?.about_club.length > 300
                              ? "...Read more"
                              : !readMore
                              ? " Read less"
                              : ""
                            : ""}
                        </span>
                      </Typography>
                    </Grid>

                    <Grid item xs={12} mt={"10px"}>
                      <div className={classes.timeAndDetails}>
                        <Typography className={classes.details}>
                          Location
                        </Typography>
                        <Typography
                          className={classes.timeDescription}
                          textAlign={!sm && "end"}
                          width={"80%"}
                        >
                          {clubData?.address}
                        </Typography>
                      </div>
                      <hr
                        style={{
                          borderTop: "2px solid white",
                          marginBottom: 16,
                          marginTop: 5,
                        }}
                      />
                      <div className={classes.timeAndDetails}>
                        <Typography className={classes.details}>
                          Website
                        </Typography>

                        <a
                          target="_blank"
                          href={
                            clubData?.course_web_link &&
                            !clubData?.course_web_link.startsWith("http")
                              ? `http://${clubData?.course_web_link}`
                              : clubData?.course_web_link
                          }
                          className={classes.timeDescription}
                          textAlign={"end"}
                          width={"80%"}
                          style={{ fontFamily: FontFamily.Bold }}
                        >
                          Visit club website
                        </a>
                      </div>
                      <hr
                        style={{
                          borderTop: "2px solid white",
                          marginBottom: 16,
                          marginTop: 5,
                        }}
                      />
                      <div className={classes.timeAndDetails}>
                        <Typography className={classes.details}>
                          Type
                        </Typography>
                        <Typography className={classes.timeDescription}>
                          {clubData?.type}
                        </Typography>
                      </div>
                      <hr
                        style={{
                          borderTop: "2px solid white",
                          marginTop: 5,
                          marginBottom: 16,
                        }}
                      />
                      <div className={classes.timeAndDetails}>
                        <Typography className={classes.details}>
                          Holes
                        </Typography>
                        <Typography className={classes.timeDescription}>
                          {clubData?.holes}
                        </Typography>
                      </div>
                      <hr
                        style={{
                          borderTop: "2px solid white",
                          marginTop: 5,
                          marginBottom: 16,
                        }}
                      />
                      <div className={classes.timeAndDetails}>
                        <Typography className={classes.details}>
                          Courses
                        </Typography>
                        <Typography className={classes.timeDescription}>
                          {clubData?.courses}
                        </Typography>
                      </div>
                      <hr
                        style={{
                          borderTop: "2px solid white",
                          marginTop: 5,
                          marginBottom: 16,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} mb={lg && "30px"}>
                      <div>
                        <Typography className={classes.details}>
                          Members
                        </Typography>
                      </div>
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          marginTop: "15px",
                          width: !lg ? "38vw" : md ? "91.5vw" : "83vw",
                          overflowX: "auto",
                        }}
                        className={classes.scrollBar}
                      >
                        {!_.isEmpty(allMembersData) ? (
                          allMembersData?.map((data) => (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                cursor: !isMobile && !isTablet && "pointer",
                              }}
                              onClick={() => {
                                setOpenProfileDetailsModal(true);
                                setProfileData(
                                  allMembersData?.find(
                                    (item) => item?.id === data?.id
                                  )
                                );
                              }}
                            >
                              <img
                                src={
                                  data?.profile_pic ||
                                  "https://i.ibb.co/D4kB907/Goat-01-1.png"
                                }
                                alt="member"
                                draggable={false}
                                style={{
                                  width: "80px",
                                  height: "80px",
                                  borderRadius: "50%",
                                  marginRight: "20px",
                                  objectFit: "cover",
                                }}
                              ></img>
                              <Typography
                                style={{
                                  textAlign: "center",
                                  overflowWrap: "break-word",
                                  marginRight: "20px",
                                  maxWidth: "85px",
                                }}
                              >
                                {data?.nick_name}
                              </Typography>
                            </div>
                          ))
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                              marginBottom: "30px",
                            }}
                          >
                            <Typography style={{ color: BaseColor.blackColor }}>
                              No Members
                            </Typography>
                          </div>
                        )}
                      </motion.div>
                      <hr
                        style={{
                          borderTop: "1px solid white",
                          marginBottom: 16,
                          marginTop: "1vh",
                          width: "100%",
                        }}
                      />
                      {!_.isEmpty(allMembersData) && (
                        <div
                          style={{
                            backgroundColor: BaseColor.green,
                            position: "fixed",
                            right: "5%",
                            zIndex: 10,
                            padding: "10px",
                            borderRadius: "50%",
                            bottom: isMobile ? "8%" : "20%",
                            cursor: !isMobile && !isTablet && "pointer",
                          }}
                          onClick={() => {
                            const isOtherMember = allMembersData.filter(
                              (item) =>
                                Number(item?.user_id) === Number(userData?.id)
                            );
                            const isOtherMemberChat = allMembersData.filter(
                              (item) =>
                                Number(item?.user_id) !== Number(userData?.id)
                            );

                            if (
                              !_.isEmpty(isOtherMember) &&
                              _.isEmpty(isOtherMemberChat)
                            ) {
                              toast("No other members to chat", {
                                type: "info",
                              });
                            } else {
                              setOpenChatWithMemberModal(true);
                              setSendChat({
                                id: 1,
                                name: "All",
                                title: "All",
                              });
                              if (sendChat?.name === "Custom selection") {
                                const updatedMembersData = allMembersData.map(
                                  (member) => ({
                                    ...member,
                                    isSelected: false,
                                  })
                                );

                                setAllMembersData(updatedMembersData);
                              }
                            }
                          }}
                        >
                          <CIcon
                            src={Files.svgIcons.whatsappChatIcon}
                            size={30}
                            style={{
                              cursor: !isMobile && !isTablet && "pointer",
                            }}
                          />
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </motion.Grid>
              </Grid>
            </Grid>
          </Grid>
          <CModal
            visible={openProfileDetailsModal}
            onClose={() => {
              setOpenProfileDetailsModal(false);
              setProfileData({});
            }}
            children={
              <Grid
                container
                padding={isMobile ? "20px" : "40px"}
                style={{
                  position: "relative",
                  width: sm ? "90vw" : "500px",
                  maxHeight: "80vh",
                  overflowY: "auto",
                }}
                className={classes.scrollBar}
              >
                <CloseIcon
                  onClick={() => {
                    setOpenProfileDetailsModal(false);
                    setProfileData({});
                  }}
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 15,
                    fontSize: 30,
                    cursor: !isMobile && !isTablet && "pointer",
                    color: BaseColor.primary,
                    zIndex: 1,
                  }}
                />
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography
                    style={{
                      fontFamily: FontFamily.GilbertQualifiDemo,
                      fontSize: isMobile ? "20px" : "24px",
                      color: BaseColor.blackColor,
                    }}
                  >
                    Details
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  mt={"20px"}
                  flexDirection={"column"}
                >
                  <img
                    src={
                      profileData?.profile_pic ||
                      "https://i.ibb.co/D4kB907/Goat-01-1.png"
                    }
                    draggable={false}
                    alt="member"
                    style={{
                      width: sm ? "100px" : "120px",
                      height: sm ? "100px" : "120px",
                      borderRadius: "50%",
                    }}
                  ></img>
                  <Typography
                    mt={"10px"}
                    style={{ fontFamily: FontFamily.Medium, fontWeight: "600" }}
                  >
                    {profileData?.nick_name}
                  </Typography>
                  <hr
                    style={{
                      borderTop: "1px solid white",
                      marginBottom: 16,
                      marginTop: 5,
                      width: "100%",
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: FontFamily.Regular,
                        color: BaseColor.blackColor,
                        fontSize: sm && "14px",
                      }}
                    >
                      Handicap index
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: FontFamily.Regular,
                        color: BaseColor.grey,
                        fontSize: sm && "14px",
                      }}
                    >
                      {profileData?.handicap_or_ghin}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: FontFamily.Regular,
                        color: BaseColor.blackColor,
                        fontSize: sm && "14px",
                      }}
                    >
                      Have golf simulator
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: FontFamily.Regular,
                        color: BaseColor.grey,
                        fontSize: sm && "14px",
                      }}
                    >
                      {profileData?.home_golf_simulator == 1 ? "Yes" : "No"}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px",
                      fontSize: sm && "14px",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: FontFamily.Regular,
                        color: BaseColor.blackColor,
                        fontSize: sm && "14px",
                      }}
                    >
                      Enjoys music on Course
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: FontFamily.Regular,
                        color: BaseColor.grey,
                        fontSize: sm && "14px",
                      }}
                    >
                      {profileData?.listen_to_music == 1 ? "Yes" : "No"}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: FontFamily.Regular,
                        color: BaseColor.blackColor,
                        fontSize: sm && "14px",
                      }}
                    >
                      Enjoy Drinks on Course
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: FontFamily.Regular,
                        color: BaseColor.grey,
                        fontSize: sm && "14px",
                      }}
                    >
                      {profileData?.beverages_available == 1 ? "Yes" : "No"}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: FontFamily.Regular,
                        color: BaseColor.blackColor,
                        fontSize: sm && "14px",
                      }}
                    >
                      Host more than one guest
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: FontFamily.Regular,
                        color: BaseColor.green,
                        fontSize: sm && "14px",
                        textAlign: "end",
                      }}
                    >
                      {profileData?.multi_guest == 1 ? "Yes" : "No"}
                    </Typography>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  mt={"20px"}
                >
                  <CButton
                    btnStyle={{ width: "48%" }}
                    variant="outlined"
                    onClick={() => {
                      navigate("/user-profile", {
                        state: { param1: { profileData } },
                      });
                    }}
                  >
                    View Profile
                  </CButton>
                  <CButton
                    btnStyle={{ width: "48%" }}
                    disabled={
                      userData?.id == profileData?.user_id || chatBtnDisable
                    }
                    onClick={() => {
                      setChatBtnDisable(true);

                      setTimeout(() => {
                        setChatBtnDisable(false);
                      }, 4000);
                      if (Number(profileData?.is_do_not_disturb) === 1) {
                        toast("This user has enabled Do not disturb mode.", {
                          type: "info",
                        });
                      }
                      handleChatWithMember();
                    }}
                    loading={chatBtnLoader}
                  >
                    Chat
                  </CButton>
                </Grid>
              </Grid>
            }
          />
          <CModal
            visible={openChatWithMemberModal}
            onClose={() => {
              setOpenChatWithMemberModal(false);
              setSearch("");
              setAllMembersData(membersData);
            }}
            children={
              <Grid
                container
                padding={isMobile ? "20px" : "40px"}
                style={{
                  position: "relative",
                  width: md ? "90vw" : "600px",
                  maxHeight: "80vh",
                  overflowY: "auto",
                }}
                className={classes.scrollBar}
              >
                <CloseIcon
                  onClick={() => {
                    setOpenChatWithMemberModal(false);
                    setSearch("");
                    setAllMembersData(membersData);
                  }}
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 15,
                    fontSize: 30,
                    cursor: !isMobile && !isTablet && "pointer",
                    color: BaseColor.primary,
                    zIndex: 1,
                  }}
                />
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography
                    style={{
                      fontFamily: FontFamily.GilbertQualifiDemo,
                      fontSize: isMobile ? "20px" : "24px",
                      color: BaseColor.blackColor,
                    }}
                  >
                    Chat with Members
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <CInput
                    value={search}
                    onChange={(e) => {
                      const searchTerm = e;
                      setSearch(searchTerm);

                      if (!_.isEmpty(e)) {
                        const searchTermParts = searchTerm.split(" "); // Split search term by spaces
                        // const firstName = searchTermParts[0]?.toLowerCase();
                        // const lastName = searchTermParts
                        //   .slice(1)
                        //   .join("")
                        //   ?.toLowerCase();

                        const filteredDatas = filteredData.filter((member) => {
                          const fullName = `${member.nick_name}`.toLowerCase();
                          return fullName.includes(searchTerm.toLowerCase());
                        });
                        setAllMembersData(filteredDatas);
                      } else {
                        const onlyNumberRegex = /^[0-9]*$/;
                        if (onlyNumberRegex?.test(id)) {
                          getClubDetails(id, false);
                        }
                      }
                    }}
                    placeholder="Search members"
                    fullWidth
                    startIcon={
                      <CiSearch
                        style={{ fontSize: "28px", color: BaseColor.grey }}
                      />
                    }
                    search
                    endIcon={
                      !_.isEmpty(search) && (
                        <IoCloseOutline
                          onClick={() => {
                            setSearch("");
                            const onlyNumberRegex = /^[0-9]*$/;
                            if (onlyNumberRegex?.test(id)) {
                              getClubDetails(id, false);
                            }
                          }}
                          style={{
                            cursor: !isMobile && !isTablet && "pointer",
                          }}
                        />
                      )
                    }
                  />
                </Grid>

                {!_.isEmpty(allMembersData) ? (
                  <Grid
                    item
                    xs={12}
                    alignItems={"flex-start"}
                    flexWrap={"wrap"}
                    mt={"20px"}
                    maxHeight={"230px"}
                    style={{
                      overflowY: "auto",
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                    }}
                  >
                    {allMembersData?.map((data, index) => {
                      if (
                        sendChat?.name === "Custom selection" &&
                        userData?.id == data?.user_id
                      ) {
                        return null;
                      }
                      return (
                        <div
                          key={index}
                          style={{
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            flexDirection: "column",
                            cursor: !isMobile && !isTablet && "pointer",
                            marginRight: "20px",
                            marginBottom: "10px",
                          }}
                          onClick={() => {
                            if (sendChat?.name === "Custom selection") {
                              if (
                                data?.is_do_not_disturb === "1" &&
                                data.isSelected === false
                              ) {
                                toast(
                                  "This user enabled do not disturb mode.",
                                  {
                                    type: "info",
                                  }
                                );
                              }
                              if (!data?.isSelected) {
                                data.isSelected = true;
                              } else {
                                data.isSelected = false;
                              }
                              setAllMembersData([...allMembersData]);
                            }
                          }}
                        >
                          {sendChat?.name === "Custom selection" && (
                            <>
                              {data?.isSelected && (
                                <div
                                  style={{
                                    background: "#042B4860",
                                    height: "82px",
                                    width: "82px",
                                    borderRadius: "50%",
                                    position: "absolute",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    bottom:
                                      data?.nick_name?.length > 10
                                        ? "45px"
                                        : "22px",
                                  }}
                                >
                                  <IoCheckmarkOutline
                                    color={BaseColor.white}
                                    size={40}
                                  />
                                </div>
                              )}
                            </>
                          )}

                          <img
                            src={
                              data?.profile_pic ||
                              "https://i.ibb.co/D4kB907/Goat-01-1.png"
                            }
                            draggable={false}
                            alt="member"
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50%",
                            }}
                          />
                          <Typography
                            style={{
                              maxWidth: "80px",
                              textAlign: "center",
                              // textOverflow: "ellipsis",
                              // WebkitLineClamp: 1,
                              // overflow: "hidden",
                              // WebkitBoxOrient: "vertical",
                              // display: "-webkit-box",
                              wordBreak:
                                data?.nick_name?.length > 10 && "break-word",
                            }}
                          >
                            {data?.nick_name}
                          </Typography>
                        </div>
                      );
                    })}
                  </Grid>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "20px",
                      width: "100%",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography sx={{ color: BaseColor.blackColor }}>
                      No members found
                    </Typography>
                  </div>
                )}

                <hr
                  style={{
                    borderTop: `1px solid ${BaseColor.cursorColor}`,
                    marginBottom: 16,
                    marginTop: 5,
                    width: "100%",
                  }}
                />

                <Grid item xs={12}>
                  <CSelect
                    fullObj
                    data={customerSelectionData}
                    value={sendChat}
                    onSelect={(val) => {
                      setSendChat(val);

                      if (val?.name === "Custom selection") {
                        const updatedMembersData = allMembersData.map(
                          (member) => ({
                            ...member,
                            isSelected: false,
                          })
                        );

                        setAllMembersData(updatedMembersData);
                      }
                    }}
                    style={{ width: "220px" }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <CInput
                    placeholder="Type message here"
                    value={message}
                    onChange={(val) => {
                      setMessage(val);
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  mt={"50px"}
                >
                  <CButton
                    disabled={message.trim() === "" || sendBtnDisable}
                    onClick={() => {
                      setSendBtnDisable(true);
                      handleChatWithMember();
                      setTimeout(() => {
                        setSendBtnDisable(false);
                      }, 5000);
                    }}
                  >
                    {sendChat.name === "All" ? "Send to all" : "Send"}
                  </CButton>
                </Grid>
              </Grid>
            }
          />

          <Grid item xs={0.5} sm={0.5} md={1} lg={1} />
        </>
      )}
    </Grid>
  );
};

export default ClubDetails;
