import React, { useState, useEffect, useRef } from "react";
import styles from "./styles";
import {
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Setting } from "../../../Utils/Setting";
import GoogleMapReact from "google-map-react";
import CMarker from "../../../Components/CMarker";
import theme, { FontFamily } from "../../../Config/theme";
import _, { isEmpty } from "lodash";
import CClubCard from "../../../Components/CClubCard";
import BaseColor from "../../../Config/colors";
import CInput from "../../../Components/CInput";
import { CiSearch } from "react-icons/ci";
import { IoCloseOutline } from "react-icons/io5";
import CIcon from "../../../Components/CIcon";
import Files from "../../../Config/Files";
import CloseIcon from "@mui/icons-material/Close";
import CModal from "../../../Components/CModal";
import { isIOS, isMobile, isTablet } from "react-device-detect";
import CSelect from "../../../CSelect";
import CCheckBox from "../../../Components/CCheckBox";
import CButton from "../../../Components/CButton";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import { getAPIProgressData } from "../../../Utils/APIHelper";
import authActions from "../../../Redux/reducers/auth/actions";
import { toast } from "react-toastify";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Lottie from "lottie-react";
import PlacesAutocomplete from "../../../Components/PlaceAutoComplete/index";

const MapComponent = () => {
  //ref
  const searchRef = useRef();

  // styles and media queries
  const classes = styles();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  // Redux
  const {
    setLatLng,
    setBackLatLng,
    setBackState,
    setBackSearch,
    setBackLocationLatLng,
  } = authActions;
  const dispatch = useDispatch();
  const mapRef = useRef(null);
  const handleApiLoaded = ({ map, maps }) => {
    mapRef.current = map;
  };
  const latLng = useSelector((state) => state.auth.latLng);
  const backLatLng = useSelector((state) => state.auth.backLatLng);
  const backState = useSelector((state) => state.auth.backState);
  const backSearch = useSelector((state) => state.auth.backSearch);
  const backLocationLatLng = useSelector(
    (state) => state.auth.backLocationLatLng
  );

  // navigate
  const navigate = useNavigate();

  // marker click of map
  const [isMarkerClick, setIsMarkerClick] = useState(false);

  // center lat lng of map
  const [mapCenter, setMapCenter] = useState({});

  // set params to url
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedFilter = searchParams.has("selected_tab")
    ? searchParams.get("selected_tab")
    : "Map";
  const [selectedTab, setSelectedTab] = useState(selectedFilter);

  // map loader
  const [mapLoader, setMapLoader] = useState(false);

  const setHeaderData = (tab = "Map") => {
    let params = {};
    if (!_.isEmpty(tab)) {
      params.selected_tab = tab;
    }
    setSearchParams(createSearchParams(params), { replace: true });
  };

  // page loader
  const [pageLoader, setPageLoader] = useState(false);
  const [isSearchFilter, setIsSearchFilter] = useState(false);
  const [isStatic, setIsStatic] = useState(true);

  // search
  const [searchValue, setSearchValue] = useState(backSearch || "");

  // location permission
  const [locationData, setLocationData] = useState([]);

  // normalize the lng because sometimes get minus lng
  const normalizeLng = (lng) => {
    if (lng < -180) return lng + 360;
    if (lng > 180) return lng - 360;
    return lng;
  };

  // map onchange event
  const handleMapChange = ({ center, zoom }) => {
    const validLng = normalizeLng(center.lng);
    setIsMarkerClick(false);
    setZoom(zoom);
    getClubList(
      center.lat,
      validLng,
      state?.name,
      searchValue?.trim(),
      "",
      false,
      false,
      false,
      backLocationLatLng?.lat,
      backLocationLatLng?.lng,
      zoom,
      true
    );
  };

  // expanded icon click Map
  const [isExpanded, setIsExpanded] = useState(false);

  // expand icon click map
  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  // location permission enable modal
  const [locationPermissionModal, setLocationPermissionModal] = useState(false);

  // filter modal
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [state, setState] = useState(backState || {});
  const [stateError, setStateError] = useState("");
  const [checked, setChecked] = useState(false);
  const [applyBtnLoading, setApplyBtnLoading] = useState(false);

  // zoom value of map
  const [zoom, setZoom] = useState(
    latLng?.lat == "39.8283" && latLng?.lng == "-98.5795"
      ? 4
      : !_.isEmpty(backLatLng)
      ? 12
      : 9
  ); // Initial zoom level

  // state list
  const [stateList, setStateList] = useState([]);

  useEffect(() => {
    getLocationPermission();
    setHeaderData(selectedFilter);
    // getStateList();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (_.isEmpty(searchValue) && isSearchFilter) {
        getClubList(
          backLatLng?.lat || latLng?.lat,
          backLatLng?.lng || latLng?.lng,
          state?.name,
          searchValue?.trim(),
          "",
          false,
          false,
          false,
          backLocationLatLng?.lat,
          backLocationLatLng?.lng,
          zoom,
          true
        );
      }
    }, 400);
  }, [searchValue]);

  // get location permission
  const getLocationPermission = () => {
    if (!_.isEmpty(latLng)) {
      getClubList(
        backState?.lat || backLatLng?.lat || latLng?.lat,
        backState?.lng || backLatLng?.lng || latLng?.lng,
        state?.name,
        searchValue?.trim(),
        true,
        false,
        false,
        false,
        backLocationLatLng?.lat,
        backLocationLatLng?.lng,
        zoom
      );
    } else {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((result) => {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                dispatch(
                  setLatLng({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                  })
                );
                getClubList(
                  position.coords.latitude,
                  position.coords.longitude,
                  state?.name,
                  searchValue?.trim(),
                  false,
                  false,
                  false,
                  false,
                  backLocationLatLng?.lat,
                  backLocationLatLng?.lng,
                  zoom
                );
              },
              (error) => {
                if (error.code === error.PERMISSION_DENIED) {
                  setLocationPermissionModal(true);
                } else {
                  console.error("Error getting location:", error);
                }
              }
            );
          } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                dispatch(
                  setLatLng({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                  })
                );
                getClubList(
                  position.coords.latitude,
                  position.coords.longitude,
                  state?.name,
                  searchValue?.trim(),
                  false,
                  false,
                  false,
                  false,
                  backLocationLatLng?.lat,
                  backLocationLatLng?.lng,
                  zoom
                );
              },
              (error) => {
                if (error.code === error.PERMISSION_DENIED) {
                  setLocationPermissionModal(true);
                } else {
                  console.error("Error getting location:", error);
                }
              }
            );
          } else {
            setLocationPermissionModal(true);
          }
        })
        .catch((error) => {
          console.error("Error requesting geolocation permission:", error);
        });
    }
  };

  // club list
  const getClubList = async (
    lat,
    lng,
    state = "",
    name = "",
    pageLoad,
    btnLoad,
    drag_map = false,
    closeModal = true,
    location_lat,
    location_lng,
    zoom_value,
    map_load
  ) => {
    if (pageLoad) {
      setPageLoader(true);
    }
    if (btnLoad) {
      setApplyBtnLoading(true);
    }
    if (map_load) {
      setMapLoader(true);
    }
    let endPoints = Setting.endpoints.getMapLocation;

    const validLng = normalizeLng(lng);

    let data = {
      lat: lat,
      lng: validLng,
      drag_map: drag_map,
    };
    if (
      !_.isNull(location_lat) &&
      !_.isUndefined(location_lat) &&
      !_.isNull(location_lng) &&
      !_.isUndefined(location_lat) &&
      !_.isNaN(location_lat) &&
      !_.isNaN(location_lng)
    ) {
      data.location_lat = location_lat;
      data.location_lng = location_lng;
    }

    if (!_.isNull(zoom_value) && !_.isUndefined(zoom_value)) {
      data.zoom_value = zoom_value;
    }

    if (!_.isEmpty(name)) {
      data.name = name;
    }

    if (!_.isEmpty(state)) {
      data.state = state;
    }

    try {
      const resp = await getAPIProgressData(endPoints, "POST", data, true);
      if (resp?.success) {
        if (!_.isEmpty(resp?.data) && _.isArray(resp?.data)) {
          setLocationData(resp?.data);
        } else {
          if (
            resp?.message === "Invalid longitude. Must be between -180 and 180."
          ) {
            getClubList(latLng?.lat, latLng?.lng, "", "", false);
          }
          setLocationData([]);
        }
        setMapLoader(false);
        if (pageLoad) {
          setPageLoader(false);
        }
        if (btnLoad) {
          setApplyBtnLoading(false);
        }

        if (closeModal) {
          setOpenFilterModal(false);
        }
      } else {
        if (pageLoad) {
          setPageLoader(false);
        }
        if (btnLoad) {
          setApplyBtnLoading(false);
        }
        setLocationData([]);
        setMapLoader(false);
      }
    } catch (error) {
      toast(error.message, { type: "error" });
      if (pageLoad) {
        setPageLoader(false);
      }
      if (btnLoad) {
        setApplyBtnLoading(false);
      }
      setMapLoader(false);
    }
  };

  // state list
  const getStateList = async () => {
    let endPoints = Setting.endpoints.getStateList;
    try {
      const resp = await getAPIProgressData(endPoints, "POST", {}, true);
      if (resp?.success) {
        if (!_.isEmpty(resp?.data) && _.isArray(resp?.data)) {
          const responseData = resp?.data.map((obj) => {
            return {
              ...obj,
              name: obj.State,
              ..._.omit(obj, ["State"]),
            };
          });
          setStateList(responseData);
        }
      } else {
        toast(resp?.message, {
          type: "error",
        });
      }
    } catch (error) {
      toast(error.message, { type: "error" });
    }
  };

  // validation state filter
  const filterValidation = () => {
    if (_.isEmpty(state) && !checked) {
      setStateError("One of the options from the above is required.");
    } else {
      dispatch(setBackState(state));
      setIsSearchFilter(true);
      setIsMarkerClick(false);
      getClubList(
        latLng?.lat,
        latLng?.lng,
        checked ? "5" : state?.name,
        "",
        false,
        true
      );
      setZoom(5);
      dispatch(
        setBackLatLng({ lat: Number(state?.lat), lng: Number(state?.lng) })
      );
      setIsStatic(false);
    }
  };

  // map drag event
  const handleMapDrag = (map) => {
    setIsSearchFilter(false);
    setIsMarkerClick(false);
    const newCenter = map?.center;

    map.panTo(newCenter);
    const validLng = normalizeLng(newCenter?.lng());
    getClubList(
      newCenter?.lat(),
      validLng,
      "",
      searchValue?.trim(),
      false,
      false,
      true,
      false,
      backLocationLatLng?.lat,
      backLocationLatLng?.lng,
      zoom
    );
  };

  return (
    <Grid container>
      {pageLoader ? (
        <Grid
          container
          style={{
            height: md ? "calc(100vh - 150px)" : "calc(100vh - 110px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={40} />
        </Grid>
      ) : (
        <>
          <Grid
            item
            xs={12}
            display={"flex"}
            alignItems={"center"}
            mt={md && "20px"}
          >
            <Grid
              container
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={5}
                display={"fex"}
                alignItems={"center"}
              >
                <div className={classes.switchDiv}>
                  <div
                    style={{
                      backgroundColor:
                        selectedTab === "Map"
                          ? BaseColor.primary
                          : BaseColor.offWhiteBg,
                      color:
                        selectedTab === "Map"
                          ? BaseColor.white
                          : BaseColor.blackColor,
                      width: !md && "200px",
                      fontFamily: FontFamily.Medium,
                    }}
                    onClick={() => {
                      setSelectedTab("Map");
                      setHeaderData("Map");
                    }}
                    className={classes.switchTab}
                  >
                    Map
                  </div>
                  <div
                    style={{
                      backgroundColor:
                        selectedTab === "List"
                          ? BaseColor.primary
                          : BaseColor.offWhiteBg,
                      color:
                        selectedTab === "List"
                          ? BaseColor.white
                          : BaseColor.blackColor,
                      width: !md && "200px",
                      fontFamily: FontFamily.Medium,
                    }}
                    onClick={() => {
                      setSelectedTab("List");
                      setHeaderData("List");
                    }}
                    className={classes.switchTab}
                  >
                    List
                  </div>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={5}
                display={"fex"}
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                <div
                  className={classes.searchAndFilter}
                  style={{ width: md && "100%" }}
                >
                  <div style={{ width: "100%" }}>
                    <PlacesAutocomplete
                      clearIcon
                      placeholder={"Search..."}
                      onChange={(val) => {
                        setSearchValue(val?.location);
                        dispatch(setBackSearch(val?.location));
                        dispatch(
                          setBackLocationLatLng({
                            lat: Number(val?.lat),
                            lng: Number(val?.lng),
                          })
                        );
                        mapRef?.current?.setCenter({
                          lat: Number(val?.lat),
                          lng: Number(val?.lng),
                        });
                        setZoom(9);
                        if (searchRef.current) clearTimeout(searchRef.current);
                        searchRef.current = setTimeout(() => {
                          setIsSearchFilter(true);
                          setIsStatic(false);
                          setIsMarkerClick(false);
                          getClubList(
                            val?.lat,
                            val?.lng,
                            state.name,
                            val?.location,
                            false,
                            false,
                            false,
                            false,
                            val?.lat,
                            val?.lng,
                            zoom,
                            true
                          );
                        }, 400);

                        dispatch(setBackLatLng({}));
                      }}
                      value={searchValue}
                      notShowLabel
                      onClear={() => {
                        setIsStatic(true);
                        setZoom(9);
                        getClubList(latLng?.lat, latLng?.lng, "", "", false);
                        setSearchValue("");
                        dispatch(setBackSearch(""));
                        dispatch(setBackLatLng({}));
                        dispatch(setBackLocationLatLng({}));
                      }}
                    />
                    {/* <CInput
                      value={searchValue}
                      onChange={(e) => {
                        setSearchValue(e?.trimLeft());
                        dispatch(setBackSearch(e?.trimLeft()));
                        if (searchRef.current) clearTimeout(searchRef.current);
                        searchRef.current = setTimeout(() => {
                          if (e?.trim() !== "") {
                            setIsSearchFilter(true);
                            setIsStatic(false);
                            setIsMarkerClick(false);
                            getClubList(
                              latLng?.lat,
                              latLng?.lng,
                              state.name,
                              e?.trim(),
                              "",
                              false,
                              false
                            );
                          }
                        }, 400);
                        if (_.isEmpty(e)) {
                          getClubList(
                            latLng?.lat,
                            latLng?.lng,
                            state?.name,
                            searchValue?.trim(),
                            "",
                            false
                          );
                          setIsStatic(true);
                        }

                        dispatch(setBackLatLng({}));
                      }}
                      placeholder={md ? "Search golf club" : "Search"}
                      fullWidth
                      startIcon={<CiSearch style={{ fontSize: "25px" }} />}
                      search
                      type="map"
                      endIcon={
                        !_.isEmpty(searchValue) && (
                          <IoCloseOutline
                            onClick={() => {
                              setSearchValue("");
                              dispatch(setBackLatLng({}));
                              dispatch(setBackSearch(""));
                              setIsStatic(true);
                              setZoom(9);
                              // getClubList(latitude, longitude);
                              getClubList(
                                latLng?.lat,
                                latLng?.lng,
                                "",
                                "",
                                false
                              );
                            }}
                            style={{
                              cursor: !isMobile && !isTablet && "pointer",
                            }}
                          />
                        )
                      }
                    /> */}
                    <Typography
                      style={{
                        color: BaseColor.grey,
                        fontSize: isMobile ? "10px" : "11.5px",
                        marginTop: "5px",
                        marginLeft: "18px",
                        fontFamily: FontFamily.Medium,
                      }}
                    >
                      Search by zip code, city name, club name.
                    </Typography>
                  </div>
                  {/* <div
                    className={classes.filterIcon}
                    onClick={() => {
                      setOpenFilterModal(true);
                    }}
                  >
                    <CIcon
                      src={Files.svgIcons.filterIcon}
                      style={{ cursor: "pointer" }}
                    />
                  </div> */}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} paddingTop={"20px"}>
            <div
              style={{
                position:
                  isExpanded && isIOS && isMobile ? "absolute" : "relative",
                zIndex: isExpanded && isIOS && isMobile ? 111111 : null,
                width: "100%",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                height:
                  isExpanded && isIOS && isMobile
                    ? "100%"
                    : selectedTab === "Map" && md
                    ? "50vh"
                    : selectedTab === "Map"
                    ? "70vh"
                    : md
                    ? "300px"
                    : "430px",
                overflow: "hidden",
                border: "0.5px solid #fffff",
                boxSizing: "border-box",
              }}
            >
              {isIOS &&
                isMobile &&
                (isExpanded ? (
                  <ZoomInMapIcon
                    style={{
                      position: "absolute",
                      top: 15,
                      right: 15,
                      backgroundColor: "white",
                      zIndex: 111,
                      width: 30,
                      height: 30,
                    }}
                    onClick={handleExpand}
                  />
                ) : (
                  <ZoomOutMapIcon
                    style={{
                      position: "absolute",
                      top: 15,
                      right: 15,
                      backgroundColor: "white",
                      zIndex: 111,
                      padding: 5,
                    }}
                    onClick={handleExpand}
                  />
                ))}

              {mapLoader && (
                <div
                  style={{
                    width: "100px",
                    height: "30px",
                    position: "absolute",
                    zIndex: 1,
                    left: "50%",
                    transform: "translateX(-50%)",
                    backgroundColor: BaseColor.white,
                    top: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "10px",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      height: 40,
                      width: 200,
                      top: "-130%",
                      zIndex: 10,
                    }}
                  >
                    <Lottie
                      animationData={Files.lottie.mapLoader}
                      loop={true}
                      height={50}
                      width="100%"
                    />
                  </div>
                </div>
              )}

              <GoogleMapReact
                zoom={zoom}
                bootstrapURLKeys={{
                  key: Setting.googleMapApiKey,
                }}
                options={{
                  fullscreenControl: true,
                  zoomControl: true,
                }}
                onChange={handleMapChange}
                onGoogleApiLoaded={handleApiLoaded}
                center={
                  isMarkerClick
                    ? mapCenter
                    : !_.isEmpty(backLatLng)
                    ? { lat: backLatLng?.lat, lng: backLatLng?.lng }
                    : isStatic && { lat: latLng?.lat, lng: latLng?.lng }
                }
                onDragEnd={(map) => {
                  // if (isEmpty(searchValue) && isEmpty(state)) {
                  handleMapDrag(map);
                  setSearchValue("");
                  dispatch(setBackLatLng({}));
                  dispatch(setBackSearch(""));
                  dispatch(setBackLocationLatLng({}));
                  setIsStatic(false);
                  // }
                }}
                defaultZoom={9}
              >
                {locationData.map((data) => (
                  <div key={data?.id} lat={data.lat} lng={data.lng}>
                    <CMarker
                      onClick={() => {
                        setIsStatic(false);
                        setIsMarkerClick(true);
                        mapRef?.current?.setCenter({
                          lat: Number(data?.lat),
                          lng: Number(data?.lng),
                        });
                        setMapCenter({
                          lat: Number(data?.lat),
                          lng: Number(data?.lng),
                        });
                        setZoom(12);
                        dispatch(
                          setBackLatLng({
                            lat: Number(data?.lat),
                            lng: Number(data?.lng),
                            zoom: 12,
                          })
                        );
                      }}
                      lat={data?.lat}
                      lng={data.lng}
                      name={data.name}
                      img={data?.club_image_url}
                      data={data}
                      zoom={zoom}
                    />
                  </div>
                ))}
              </GoogleMapReact>
              {!_.isEmpty(state) && (
                <div
                  style={{
                    backgroundColor: BaseColor.white,
                    // maxWidth: "230px",
                    // height: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    bottom: isMobile ? 10 : 50,
                    margin: "auto",
                    borderRadius: "20px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    padding: "5px 10px",
                    width: "max-content",
                  }}
                >
                  <Typography
                    sx={{
                      color: BaseColor.blackColor,
                      fontFamily: FontFamily.Regular,
                    }}
                  >
                    {state?.name}
                  </Typography>
                  <CloseIcon
                    style={{
                      fontSize: 22,
                      cursor: !isMobile && !isTablet && "pointer",
                      color: BaseColor.primary,
                      zIndex: 1,
                      marginLeft: "10px",
                    }}
                    onClick={() => {
                      getClubList(
                        latLng?.lat,
                        latLng?.lng,
                        "",
                        searchValue?.trim(),
                        "",
                        false
                      );
                      setIsStatic(true);
                      setState({});
                      setZoom(9);
                      dispatch(setBackState({}));
                      dispatch(setBackLatLng({}));
                    }}
                  />
                </div>
              )}
            </div>
          </Grid>
          {selectedTab === "List" && (
            <>
              <Grid item xs={12} mt={md ? "10px" : "20px"}>
                <Typography className={classes.title}> Clubs</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                xl={12}
                style={{
                  overflowX: "auto",
                }}
                mb={"20px"}
                className={classes.scrollBar}
              >
                {!_.isEmpty(locationData) ? (
                  <Grid
                    container
                    style={{
                      flexWrap: "noWrap",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{
                        paddingBottom: 20,
                        paddingTop: 20,
                        display: "flex",
                      }}
                    >
                      <Grid
                        container
                        gap={2}
                        style={{
                          flexWrap: md ? "" : "nowrap",
                          paddingLeft: "2px",
                          paddingRight: "2px",
                        }}
                      >
                        {locationData?.map((item, index) => {
                          return (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={4}
                              lg={4}
                              xl={4}
                              style={{ overflowY: !md && "hidden" }}
                            >
                              <CClubCard
                                style={{ width: md ? "90vw" : "27.2vw" }}
                                name={item.name}
                                address={item.address}
                                image={item.club_image_url}
                                onClick={() => {
                                  navigate(`/club-details/${item?.id}`);
                                }}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={12}
                    style={{
                      border: `1px solid ${BaseColor.cursorColor}`,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100px",
                      marginTop: "10px",
                    }}
                  >
                    <Typography
                      style={{
                        color: BaseColor.grey,
                        fontFamily: FontFamily.Regular,
                      }}
                    >
                      No clubs
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </>
          )}

          <CModal
            visible={openFilterModal}
            onClose={() => {
              setOpenFilterModal(false);
              setState({});
              dispatch(setBackState({}));
              dispatch(setBackLatLng({}));
              setChecked(false);
              setStateError("");
            }}
            children={
              <Grid
                container
                padding={isMobile ? "20px" : "40px"}
                style={{
                  position: "relative",
                  width: sm ? "90vw" : "470px",
                  maxHeight: "80vh",
                  overflowY: "auto",
                }}
                className={classes.scrollBar}
              >
                <CloseIcon
                  onClick={() => {
                    setOpenFilterModal(false);
                    setState({});
                    setChecked(false);
                    setStateError("");
                    dispatch(setBackState({}));
                    dispatch(setBackLatLng({}));
                  }}
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 15,
                    fontSize: 30,
                    cursor: !isMobile && !isTablet && "pointer",
                    color: BaseColor.primary,
                    zIndex: 1,
                  }}
                />

                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography
                    style={{
                      fontFamily: FontFamily.GilbertQualifiDemo,
                      fontSize: isMobile ? "20px" : "24px",
                      color: BaseColor.blackColor,
                    }}
                  >
                    Filter
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  mt={"20px"}
                >
                  <Typography
                    sx={{
                      marginTop: "10px",
                      fontSize: sm ? "14px" : "16px",
                      color: BaseColor.blackColor,
                      fontFamily: FontFamily.Medium,
                    }}
                  >
                    Select state
                  </Typography>
                  <CSelect
                    state
                    placeholder={"Select state"}
                    data={stateList}
                    style={{ width: "60%" }}
                    fullObj
                    value={state}
                    onSelect={(val) => {
                      setState(val);
                      setChecked(false);
                      setStateError("");
                    }}
                    isMap
                    onClear={() => {
                      setState({});
                      setStateError("");
                      setIsStatic(true);
                      dispatch(setBackState({}));
                      dispatch(setBackLatLng({}));
                      getClubList(
                        latLng?.lat,
                        latLng?.lng,
                        "",
                        "",
                        false,
                        false,
                        false,
                        false
                      );
                    }}
                  />
                  {/* {!_.isEmpty(state) && (
                    <CloseIcon
                      onClick={() => {}}
                      style={{
                        cursor: !isMobile && !isTablet && "pointer",
                        color: BaseColor.primary,
                        zIndex: 1,
                      }}
                    />
                  )} */}
                </Grid>
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  alignItems={"center"}
                  mt={"20px"}
                >
                  <CCheckBox
                    checked={checked}
                    onChange={(val) => {
                      setChecked(val);
                      setState({});
                      dispatch(setBackState({}));
                      setStateError("");
                    }}
                    style={{
                      color: BaseColor.primary,
                      padding: 0,
                      marginRight: 5,
                    }}
                    label="5 Nearest clubs"
                  />
                </Grid>
                {!_.isEmpty(stateError) && (
                  <div
                    className={classes.errorMsgContainer}
                    style={{ marginTop: "10px" }}
                  >
                    <span className={classes.errorMsgText}>*{stateError}</span>
                  </div>
                )}

                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  alignItems={"center"}
                  mt={"120px"}
                  gap={2}
                >
                  {checked && (
                    <CButton
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        setChecked(false);
                      }}
                    >
                      Reset
                    </CButton>
                  )}
                  <CButton
                    loading={applyBtnLoading}
                    onClick={() => {
                      filterValidation();
                    }}
                  >
                    Apply
                  </CButton>
                </Grid>
              </Grid>
            }
          />
          <CModal
            visible={locationPermissionModal}
            onClose={() => {
              dispatch(
                setLatLng({
                  lat: 39.8283,
                  lng: -98.5795,
                })
              );
              getClubList(39.8283, -98.5795);
              setLocationPermissionModal(false);
            }}
            children={
              <Grid
                container
                padding={isMobile ? "20px" : "40px"}
                style={{
                  position: "relative",
                  width: md ? "90vw" : "600px",
                  maxHeight: "80vh",
                  overflowY: "auto",
                }}
                className={classes.scrollBar}
              >
                <CloseIcon
                  onClick={() => {
                    dispatch(
                      setLatLng({
                        lat: 39.8283,
                        lng: -98.5795,
                      })
                    );
                    getClubList(39.8283, -98.5795);
                    setLocationPermissionModal(false);
                  }}
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 15,
                    fontSize: 30,
                    cursor: !isMobile && !isTablet && "pointer",
                    color: BaseColor.primary,
                    zIndex: 1,
                  }}
                />
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Lottie
                    animationData={Files.lottie.locationLottie}
                    loop={true}
                    style={{
                      height: "150px",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography
                    style={{
                      fontFamily: FontFamily.GilbertQualifiDemo,
                      fontSize: isMobile ? "20px" : "24px",
                      color: BaseColor.blackColor,
                    }}
                  >
                    Enable location permission
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  mt={"20px"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography
                    style={{
                      fontFamily: FontFamily.Medium,
                      color: BaseColor.blackColor,
                    }}
                  >
                    Please enable location permission for continue
                  </Typography>
                </Grid>
              </Grid>
            }
          />
        </>
      )}
    </Grid>
  );
};

export default MapComponent;
