import {
  Badge,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styles from "./styles";
import CReservationCard from "../../Components/CReservationCard";
import _ from "lodash";
import theme, { FontFamily } from "../../Config/theme";
import BaseColor from "../../Config/colors";
import CEventCard from "../../Components/CEventsCard";
import CClubCard from "../../Components/CClubCard";
import GoogleMapReact from "google-map-react";
import { Setting } from "../../Utils/Setting";
import { toast } from "react-toastify";
import { getApiData, getAPIProgressData } from "../../Utils/APIHelper";
import CModal from "../../Components/CModal";
import { isIOS, isMobile, isTablet } from "react-device-detect";
import CloseIcon from "@mui/icons-material/Close";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import { useNavigate } from "react-router-dom";
import "../ReservationList/CustomCalendar.css";
import "react-calendar/dist/Calendar.css";
import { useDispatch, useSelector } from "react-redux";
import { IoCheckmarkOutline } from "react-icons/io5";
import authActions from "../../Redux/reducers/auth/actions";
import ReservationModel from "../../Components/ReservationModel/ReservationModel";
import CMarker from "../../Components/CMarker";
import Files from "../../Config/Files";
import Lottie from "lottie-react";
import { motion } from "framer-motion";

const HomeComponent = () => {
  // styles
  const classes = styles();

  // navigation
  const navigate = useNavigate();

  //ref
  const mapRef = useRef();

  //responsive design
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const xl = useMediaQuery(theme.breakpoints.down("xl"));

  // get data and set data from redux
  const dispatch = useDispatch();
  const latLng = useSelector((state) => state.auth.latLng);
  const homeBackLatLng = useSelector((state) => state.auth.homeBackLatLng);
  const { setLatLng, setCTab, setHomeBackLatLng } = authActions;

  // page loader
  const [pageLoader, setPageLoader] = useState(true);

  // location permission
  const [locationData, setLocationData] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);

  // location permission enable modal
  const [locationPermissionModal, setLocationPermissionModal] = useState(false);

  // event list data
  const [eventsData, setEventsData] = useState([]);

  // reservation modal
  const [showReservationModel, setShowReservationModel] = useState({
    vis: false,
    data: [],
  });

  // reservation list
  const [revLoader, setRevLoader] = useState(false);
  const [reservationArray, setReservationArray] = useState([]);

  // create btn loader
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const mobileScreen = useMediaQuery("(max-width:600px)");
  const [calendarModal, setCalendarModal] = useState(false);
  const [selectedCalendarData, setSelectedCalendarData] = useState({});
  const [selectedMonth, setSelectedMonth] = useState(null); // Initialize with the current date
  const [isCalendarOpen, setIsCalendarOpen] = useState(false); // State to manage calendar visibility
  const [mapLoaded, setMapLoaded] = useState(false);

  // map loader for map onchange
  const [mapLoader, setMapLoader] = useState(false);

  // marker click
  const [isMarkerClick, setIsMarkerClick] = useState(false);

  // map center lat lng
  const [mapCenter, setMapCenter] = useState({});

  // store link
  const [storeLink, setStoreLink] = useState("");

  // map loaded
  const handleApiLoaded = ({ map }) => {
    mapRef.current = map;
    setMapLoaded(true);
  };

  // open iframe store
  const openInNewTab = () => {
    window.open(storeLink, "_blank");
  };

  // showing store
  const iframeContainerStyle = {
    position: "relative",
    height: isMobile ? "250px" : "300px", // Adjust this size as per your design
    cursor: "pointer",
    border: "1px solid #ccc", // Optional
    borderRadius: "10px", // Optional
    overflow: "hidden",
    width: sm ? "90vw" : md ? "41vw" : "27vw",
  };

  const iframeStyle = {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    border: "none",
  };

  useEffect(() => {
    if (!_.isEmpty(latLng)) {
      getEventList();
    }
    getReservationList(
      !_.isNull(selectedMonth) ? selectedMonth.toISOString().slice(0, 7) : ""
    );
    getStoreLink();
  }, [latLng]);

  useEffect(() => {
    if (!_.isEmpty(latLng)) {
      getClubList(
        homeBackLatLng?.lat || latLng?.lat,
        homeBackLatLng?.lng || latLng?.lng,
        false,
        zoom
      );
    } else {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((result) => {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                dispatch(
                  setLatLng({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                  })
                );
                getClubList(
                  position.coords.latitude,
                  position.coords.longitude,
                  false,
                  zoom
                );
              },
              (error) => {
                if (error.code === error.PERMISSION_DENIED) {
                  setLocationPermissionModal(true);
                } else {
                  console.error("Error getting location:", error);
                }
              }
            );
          } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                dispatch(
                  setLatLng({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                  })
                );
                getClubList(
                  position.coords.latitude,
                  position.coords.longitude,
                  false,
                  zoom
                );
              },
              (error) => {
                if (error.code === error.PERMISSION_DENIED) {
                  setLocationPermissionModal(true);
                } else {
                  console.error("Error getting location:", error);
                }
              }
            );
          } else {
            setLocationPermissionModal(true);
          }
        })
        .catch((error) => {
          console.error("Error requesting geolocation permission:", error);
        });
    }
  }, []);

  // map drag event
  const handleMapDrag = (map) => {
    setIsMarkerClick(false);
    const newCenter = map?.center;
    map.panTo(newCenter);
    getClubList(newCenter?.lat(), newCenter?.lng(), true, zoom, true);
  };

  const selectedCalendarTime = new Date(
    selectedCalendarData?.start_date + "T" + selectedCalendarData?.start_time
  );
  const formatedselectedCalendarTime = selectedCalendarTime.toLocaleString(
    "en-US",
    {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }
  );

  // Parse the original date string into a Date object
  const originalDate = new Date(selectedCalendarData?.start_date);

  // Define arrays for month names and day names
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Extract day, month, and year from the Date object
  const day = originalDate.getDate();
  const monthIndex = originalDate.getMonth();
  const year = originalDate.getFullYear();
  const dayIndex = originalDate.getDay();
  const [zoom, setZoom] = useState(
    latLng?.lat == "39.8283" && latLng?.lng == "-98.5795"
      ? 4
      : !_.isEmpty(homeBackLatLng)
      ? 12
      : 8
  );

  // map change event
  const handleMapChange = ({ center, zoom }) => {
    setIsMarkerClick(false);
    setZoom(zoom);
    getClubList(center.lat, center.lng, false, zoom, true);
  };

  // Construct the desired output string
  const outputString = `${dayNames[dayIndex]} ${monthNames[monthIndex]} ${day}  ${year}`;

  // get near by clubs
  const getClubList = async (
    lat,
    lng,
    drag_map = false,
    zoom_value,
    loader = false
  ) => {
    if (loader) {
      setMapLoader(true);
    }
    let endPoints = Setting.endpoints.getMapLocation;

    const data = {
      lat: lat,
      lng: lng,
      drag_map: drag_map,
    };

    if (!_.isNull(zoom_value) && !_.isUndefined(zoom_value)) {
      data.zoom_value = zoom_value;
    }

    try {
      const resp = await getAPIProgressData(endPoints, "POST", data, true);
      if (resp?.success) {
        if (!_.isEmpty(resp?.data) && _.isArray(resp?.data)) {
          setLocationData(resp?.data);
          setMapLoader(false);
        } else {
          setLocationData([]);
          setMapLoader(false);
        }
      } else {
        toast(resp?.message, {
          type: "error",
        });
        setMapLoader(false);
      }
    } catch (error) {
      toast("Something went wrong", { type: "error" });
      setMapLoader(false);
    }
  };

  // get event list
  const getEventList = async () => {
    let endPoints = Setting.endpoints.getEventList;
    const params = {
      user_lat: latLng.lat,
      user_lng: latLng.lng,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.success) {
        if (!_.isEmpty(resp?.data) && _.isArray(resp?.data)) {
          setEventsData(resp?.data);
        }
        setPageLoader(false);
      } else {
        toast(resp?.message, {
          type: "error",
        });
      }
      setPageLoader(false);
    } catch (error) {
      toast("Something went wrong", { type: "error" });
      setPageLoader(false);
    }
  };

  // get store link
  const getStoreLink = async () => {
    let endPoints = Setting.endpoints.chatMessage;
    try {
      const resp = await getApiData(endPoints, "GET", {}, true);
      if (resp?.success) {
        if (!_.isEmpty(resp?.data)) {
          const storeLink = resp?.data?.find(
            (data) => data?.meta_type === "store_link"
          );
          setStoreLink(storeLink?.meta_name);
        }
      }
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
    }
  };

  const handleCardClick = (month) => {
    setSelectedMonth(month);
    setIsCalendarOpen(true); // Open the calendar when a card is clicked
  };
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const containerRef = useRef(null);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartX(e.touches[0].pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 4; // Adjust the scroll speed
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const x = e.touches[0].pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 4; // Adjust the scroll speed
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  // reservation list api call
  const getReservationList = async (selectedMonth) => {
    setRevLoader(true);
    const data = {
      month: selectedMonth,
    };
    try {
      const resp = await getAPIProgressData(
        `${Setting.endpoints.reservationList}`,
        "POST",
        data,
        true
      );

      if (resp?.success) {
        if (!_.isEmpty(resp?.data) && _.isArray(resp?.data)) {
          setReservationArray(resp?.data);
        } else {
          setReservationArray([]);
        }
      } else {
        setReservationArray([]);
      }
      setRevLoader(false);
      setIsCalendarOpen(false);
    } catch (error) {
      toast.error("Something went wrong", {
        position: "top-right",
      });
      setRevLoader(false);
      setIsCalendarOpen(false);
    }
  };

  // add and remove bookmarks
  const addAndRemoveBookmark = async (eventId, bookmark) => {
    let endPoints = Setting.endpoints.addAndRemoveBookmark;

    const data = {
      "EventManagement[event_id]": eventId,
      "EventManagement[is_event_bookmark]": bookmark ? 1 : 0,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", data, true);
      if (resp?.success) {
        toast(resp?.message, {
          type: "success",
        });
        getEventList();
      } else {
        toast(resp?.message, {
          type: "error",
        });
      }
    } catch (error) {
      toast(error.message, { type: "error" });
    }
  };

  const isToday = (date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const isDateDisabled = ({ date, view }) => {
    if (view === "month") {
      const isReservedDate =
        reservationArray &&
        reservationArray.find((item) => {
          // Extract YYYY-MM-DD formatted date from the reservation start_date
          const reservationDate = new Date(item.start_date);
          // Compare the date objects
          return reservationDate.toDateString() === date.toDateString();
        });
      return !isReservedDate;
    } else {
      return false;
    }
  };

  const getTileClassName = ({ date, view }) => {
    // Check if the current date is included in any of the reservation dates
    const isReservedDate =
      reservationArray &&
      reservationArray.some((item) => {
        // Extract YYYY-MM-DD formatted date from the reservation start_date
        const reservationDate = new Date(item.start_date);
        // Compare the date objects
        return reservationDate.toDateString() === date.toDateString();
      });

    if (isReservedDate && view === "month") {
      return `${classes.customTile}`;
    }

    if (
      view === "month" &&
      date.getDate() === selectedMonth.getDate() &&
      date.getMonth() === selectedMonth.getMonth() &&
      date.getFullYear() === selectedMonth.getFullYear()
    ) {
      return `${classes.customSelectedDate} `;
    }

    if (view === "month" && (date.getDay() === 0 || date.getDay() === 6)) {
      return "custom-selected-date";
    }
    if (isToday(date)) {
      return `${classes.todayDate}`;
    }

    return null;
  };

  const handleDayClick = (date) => {
    const dateStr = date;
    const newDate = new Date(dateStr);
    const year = date.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, "0");
    const day = String(newDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;

    // Find the item in reservationArray that matches the new date
    const selectedItem = reservationArray?.find((item) => {
      // Check if item and start_date exist before accessing them
      return item.start_date === formattedDate;
    });

    // Set the selected data
    setSelectedCalendarData(selectedItem);
  };
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Grid container>
      {pageLoader ? (
        <Grid
          container
          className={classes.pageLoader}
          style={{
            height: md ? "calc(100vh - 150px)" : "calc(100vh - 110px)",
          }}
        >
          <CircularProgress color="primary" size={40} />
        </Grid>
      ) : (
        <>
          <Grid
            item
            xs={12}
            className={classes.reservation}
            mt={md && "10px"}
            mb={"5px"}
          >
            <Typography className={classes.title}> My Tee Times</Typography>
            <div className={classes.createReservation}>
              <Typography
                className={classes.create}
                style={{ paddingRight: 10 }}
                onClick={() => {
                  setShowReservationModel({
                    vis: true,
                    data: [],
                  });
                }}
              >
                + Create
              </Typography>
              <Typography
                className={classes.create}
                style={{ paddingLeft: 10 }}
                onClick={() => {
                  navigate("/reservation");
                  dispatch(setHomeBackLatLng({}));
                }}
              >
                See All
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            xl={12}
            style={{
              overflowX: "auto",
            }}
            className={
              isMobile || isTablet ? classes.scrollBarMain : classes.scrollBar
            }
          >
            <Grid
              container
              style={{
                flexWrap: "noWrap",
              }}
            >
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                xl={12}
                style={{
                  paddingBottom: 15,
                  paddingTop: 10,
                  display: "flex",
                }}
              >
                <Grid
                  container
                  gap={md ? 1 : 2}
                  style={{
                    flexWrap: "nowrap",
                    paddingLeft: "2px",
                    paddingRight: "2px",
                  }}
                >
                  {revLoader ? (
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress
                        style={{ color: BaseColor.primary }}
                        size={24}
                      />
                    </Grid>
                  ) : (
                    <>
                      {_.isEmpty(reservationArray) ? (
                        <Grid
                          item
                          xs={12}
                          className={classes.noReservationContainer}
                        >
                          <Typography
                            style={{
                              color: BaseColor.grey,
                              fontFamily: FontFamily.Regular,
                            }}
                          >
                            No Tee Times Scheduled
                          </Typography>
                        </Grid>
                      ) : (
                        <>
                          {reservationArray?.slice(0, 3).map((item, index) => {
                            const parseDateString = (dateString) => {
                              // Define regular expressions to match different parts of the date string
                              const dayRegex =
                                /(Sunday|Monday|Tuesday|Wednesday|Thursday|Friday|Saturday)/;
                              const monthRegex =
                                /(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)/;
                              const dayOfMonthRegex = /(\d{1,2})/;

                              // Extract day of the week, month, and day of the month
                              const [, dayOfWeek] =
                                dateString.match(dayRegex) || [];
                              const [, monthStr] =
                                dateString.match(monthRegex) || [];
                              const [, dayOfMonthStr] =
                                dateString.match(dayOfMonthRegex) || [];
                              if (!dayOfWeek || !monthStr || !dayOfMonthStr) {
                                throw new Error("Invalid date format");
                              }

                              // Map month abbreviations to month numbers
                              const monthMap = {
                                Jan: 0,
                                Feb: 1,
                                Mar: 2,
                                Apr: 3,
                                May: 4,
                                Jun: 5,
                                Jul: 6,
                                Aug: 7,
                                Sep: 8,
                                Oct: 9,
                                Nov: 10,
                                Dec: 11,
                              };

                              // Convert extracted strings to numbers
                              const month = monthMap[monthStr];
                              const dayOfMonth = parseInt(dayOfMonthStr);

                              // Create a Date object
                              const date = new Date();
                              date.setDate(dayOfMonth);
                              date.setMonth(month);

                              // Set the day of the week
                              switch (dayOfWeek) {
                                case "Sunday":
                                  date.setDate(
                                    date.getDate() + (0 - date.getDay())
                                  );
                                  break;
                                case "Monday":
                                  date.setDate(
                                    date.getDate() + (1 - date.getDay())
                                  );
                                  break;
                                case "Tuesday":
                                  date.setDate(
                                    date.getDate() + (2 - date.getDay())
                                  );
                                  break;
                                case "Wednesday":
                                  date.setDate(
                                    date.getDate() + (3 - date.getDay())
                                  );
                                  break;
                                case "Thursday":
                                  date.setDate(
                                    date.getDate() + (4 - date.getDay())
                                  );
                                  break;
                                case "Friday":
                                  date.setDate(
                                    date.getDate() + (5 - date.getDay())
                                  );
                                  break;
                                case "Saturday":
                                  date.setDate(
                                    date.getDate() + (6 - date.getDay())
                                  );
                                  break;
                                default:
                                  throw new Error("Invalid day of the week");
                              }

                              return date;
                            };

                            let dateObject;
                            const reservationDayString = item?.reservation_day; // Replace this with your actual reservation day string
                            try {
                              dateObject =
                                parseDateString(reservationDayString);
                            } catch (error) {
                              console.error(
                                "Error parsing date:",
                                error.message
                              );
                            }
                            const startTime = new Date(
                              item.start_date + "T" + item.start_time
                            );
                            const formattedTime = startTime.toLocaleString(
                              "en-US",
                              {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              }
                            );

                            return (
                              <Grid
                                key={item.id}
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                lg={4}
                                xl={4}
                                // paddingRight={!sm && "10px"}
                                style={{ overflowY: "hidden" }}
                              >
                                <CReservationCard
                                  item={item}
                                  image={item?.club_image}
                                  style={{
                                    minWidth: md && "280px",
                                    width:
                                      !md && lg
                                        ? "41vw"
                                        : !md && xl
                                        ? "27vw"
                                        : "",
                                    height: md && "90px",
                                  }}
                                  name={item.club_name}
                                  time={`${formattedTime}- ${item?.reservation_day} `}
                                  host={
                                    item?.self_reservation === 1
                                      ? "You"
                                      : item.host_name
                                  }
                                  hostTitle={
                                    item?.self_reservation === 1
                                      ? "Reserved by: "
                                      : "Hosting: "
                                  }
                                  onClick={() => {
                                    setCalendarModal(true);
                                    setSelectedCalendarData(item);
                                    handleCardClick(dateObject);
                                  }}
                                  onChatClick={() => {
                                    const convType =
                                      item?.reservation_member_data?.length >= 2
                                        ? "groups"
                                        : "personal";
                                    if (convType === "personal") {
                                      dispatch(setCTab("personal"));
                                    } else {
                                      dispatch(setCTab("groups"));
                                    }
                                    setTimeout(() => {
                                      navigate(
                                        `/chat?c_id=${item?.conversation_id}&conv_type=${convType}`
                                      );
                                      dispatch(setHomeBackLatLng({}));
                                    }, 500);
                                  }}
                                  editResertvation={(data) => {
                                    setShowReservationModel({
                                      vis: true,
                                      data: data,
                                    });
                                    const newArr =
                                      data?.reservation_member_data;

                                    let memberArr = [];
                                    newArr.map((data) => {
                                      memberArr.push({
                                        default_quantity: Number(
                                          data?.guest_cnt
                                        ),
                                        id: data?.member_id,
                                        isSelected: true,
                                        profile_pic: null,
                                        profile_pic_url:
                                          data?.profile_pic_url ||
                                          "https://golf.teamgroovy.com/img_assets/boy.jpeg",
                                        username: data?.username,
                                        nick_name: data?.username,
                                      });
                                    });

                                    setSelectedMembers(memberArr);
                                  }}
                                />
                              </Grid>
                            );
                          })}
                        </>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.reservation} mb={"5px"}>
            <Typography className={classes.title}> Events</Typography>
            <Typography
              className={classes.create}
              onClick={() => {
                navigate("/event", {
                  state: { event_data: "event_data" },
                });
                dispatch(setHomeBackLatLng({}));
              }}
            >
              {!_.isEmpty(eventsData) && eventsData.length > 3 ? "See All" : ""}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            xl={12}
            style={{
              overflowX: "auto",
              marginBottom: "15px",
            }}
            className={
              isMobile || isTablet ? classes.scrollBarMain : classes.scrollBar
            }
          >
            {!_.isEmpty(eventsData) ? (
              <Grid
                container
                style={{
                  flexWrap: "noWrap",
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{
                    paddingBottom: md ? 5 : 10,
                    paddingTop: 10,
                    display: "flex",
                  }}
                >
                  <Grid
                    container
                    gap={2}
                    style={{
                      flexWrap: "nowrap",
                      overflowY: "hidden",
                    }}
                  >
                    {eventsData?.slice(0, 3).map((item, index) => {
                      return (
                        <Grid key={item?.id} item>
                          <CEventCard
                            style={{
                              width: sm ? "90vw" : md ? "41vw" : "27vw",
                            }}
                            name={item.event_name}
                            address={item.location}
                            distance={item.distance}
                            image={
                              _.isArray(item?.event_images)
                                ? item?.event_images[0]
                                : item?.event_images
                            }
                            onClick={() => {
                              navigate(`/event-details/${item?.id}`);
                              dispatch(setHomeBackLatLng({}));
                            }}
                            bookMarkClick={() => {
                              addAndRemoveBookmark(
                                item?.id,
                                item?.is_event_bookmark == "1" ? false : true
                              );
                            }}
                            isBookMark={
                              item?.is_event_bookmark == "1" ? true : false
                            }
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} className={classes.noEventsContainer}>
                <Typography
                  style={{
                    color: BaseColor.grey,
                    fontFamily: FontFamily.Regular,
                  }}
                >
                  No events
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} mb={"5px"}>
            <Typography
              className={classes.title}
              sx={{
                marginBottom: "10px !important",
                userSelect: "none",
                cursor: !isMobile && !isTablet && "pointer",
              }}
              onClick={() => {
                navigate("/map");
                dispatch(setHomeBackLatLng({}));
              }}
            >
              Map
            </Typography>

            <div
              style={{
                position:
                  isExpanded && isIOS && isMobile ? "absolute" : "relative",
                zIndex: isExpanded && isIOS && isMobile ? 111111 : null,
                top: -5,
                bottom: 0,
                left: 0,
                right: 0,
                height:
                  isExpanded && isIOS && isMobile
                    ? "100%"
                    : md
                    ? "182px"
                    : "421px",
                width: "100%",
                overflow: "hidden",
                borderRadius: "10px",
                border: "0.5px solid silver",
                boxSizing: "border-box",
                marginTop: "5px",
              }}
            >
              {isIOS &&
                isMobile &&
                (isExpanded ? (
                  <ZoomInMapIcon
                    style={{
                      position: "absolute",
                      top: 15,
                      right: 15,
                      backgroundColor: "white",
                      zIndex: 111,
                      width: 30,
                      height: 30,
                    }}
                    onClick={handleExpand}
                  />
                ) : (
                  <ZoomOutMapIcon
                    style={{
                      position: "absolute",
                      top: 15,
                      right: 15,
                      backgroundColor: "white",
                      zIndex: 111,
                      padding: 5,
                    }}
                    onClick={handleExpand}
                  />
                ))}
              {!mapLoaded && (
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: md ? "182px" : "421px",
                    zIndex: 1111,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backdropFilter: "blur(3px)",
                  }}
                >
                  <CircularProgress color="primary" size={30} />
                </div>
              )}

              {mapLoader && (
                <div
                  style={{
                    width: "100px",
                    height: "30px",
                    position: "absolute",
                    zIndex: 1,
                    left: "50%",
                    transform: "translateX(-50%)",
                    backgroundColor: BaseColor.white,
                    top: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "10px",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      height: 40,
                      width: 200,
                      top: "-130%",
                      zIndex: 10,
                    }}
                  >
                    <Lottie
                      animationData={Files.lottie.mapLoader}
                      loop={true}
                      height={50}
                      width="100%"
                    />
                  </div>
                </div>
              )}

              <GoogleMapReact
                ref={mapRef}
                bootstrapURLKeys={{
                  key: Setting.googleMapApiKey,
                }}
                options={{
                  fullscreenControl: true,
                  zoomControl: true,
                }}
                zoom={zoom}
                onDragEnd={(map) => {
                  handleMapDrag(map);
                }}
                onChange={handleMapChange}
                center={
                  isMarkerClick
                    ? mapCenter
                    : !_.isEmpty(homeBackLatLng)
                    ? { lat: homeBackLatLng?.lat, lng: homeBackLatLng?.lng }
                    : { lat: latLng?.lat, lng: latLng?.lng }
                }
                defaultZoom={9}
                onGoogleApiLoaded={handleApiLoaded}
              >
                {locationData?.map((data) => (
                  <div lat={data.lat} lng={data.lng}>
                    <CMarker
                      lat={data?.lat}
                      lng={data.lng}
                      name={data.name}
                      img={data?.club_image_url}
                      data={data}
                      zoom={zoom}
                      onClick={() => {
                        setIsMarkerClick(true);
                        setMapCenter({
                          lat: Number(data?.lat),
                          lng: Number(data?.lng),
                        });
                        mapRef?.current?.setCenter({
                          lat: Number(data?.lat),
                          lng: Number(data?.lng),
                        });
                        dispatch(
                          setHomeBackLatLng({
                            lat: Number(data?.lat),
                            lng: Number(data?.lng),
                            zoom: 12,
                          })
                        );
                        setZoom(12);
                      }}
                    />
                  </div>
                ))}
              </GoogleMapReact>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            className={classes.reservation}
            mt={md ? "10px" : "15px"}
            style={{
              userSelect: "none", // Disable selection for the container
            }}
            mb={"5px"}
          >
            <Typography
              className={classes.title}
              style={{
                fontFamily: FontFamily.Regular,
                color: BaseColor.grey,
              }}
            >
              Clubs
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            xl={12}
            ref={containerRef}
            style={{
              overflowX: "auto",
              marginBottom: "15px",
            }}
            className={
              isMobile || isTablet ? classes.scrollBarMain : classes.scrollBar
            }
            // {!isMobile && !isTablet  && null}
            onMouseDown={!isMobile && !isTablet ? handleMouseDown : null}
            onMouseUp={!isMobile && !isTablet ? handleMouseUp : null}
            onMouseLeave={!isMobile && !isTablet ? handleMouseUp : null}
            onMouseMove={!isMobile && !isTablet ? handleMouseMove : null}
            onTouchStart={!isMobile && !isTablet ? handleTouchStart : null}
            onTouchEnd={!isMobile && !isTablet ? handleMouseUp : null}
            onTouchMove={!isMobile && !isTablet ? handleTouchMove : null}
          >
            {!_.isEmpty(locationData) ? (
              <Grid
                container
                style={{
                  flexWrap: "noWrap",
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{
                    paddingBottom: 20,
                    paddingTop: 15,
                    display: "flex",
                  }}
                >
                  <Grid
                    container
                    gap={2}
                    style={{
                      flexWrap: "nowrap",
                      paddingLeft: "2px",
                      paddingRight: "2px",
                    }}
                  >
                    {locationData?.map((item, index) => {
                      return (
                        <Grid
                          key={item?.id}
                          item
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                          style={{ overflowY: "hidden" }}
                        >
                          <CClubCard
                            style={{
                              userSelect: "none",
                              width: sm ? "90vw" : md ? "41vw" : "27vw",
                              height: md && "250px",
                            }}
                            name={item.name}
                            address={item.address}
                            image={item.club_image_url}
                            onMouseDown={handleMouseDown}
                            onTouchStart={handleTouchStart}
                            onClick={() => {
                              navigate(`/club-details/${item?.id}`);
                              dispatch(setHomeBackLatLng({}));
                            }}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} className={classes.noClubDataContainer}>
                <Typography
                  style={{
                    color: BaseColor.grey,
                    fontFamily: FontFamily.Regular,
                  }}
                >
                  No clubs
                </Typography>
              </Grid>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            className={classes.reservation}
            mt={md ? "10px" : "15px"}
            style={{
              userSelect: "none", // Disable selection for the container
            }}
            mb={"5px"}
          >
            <Typography
              className={classes.title}
              style={{
                fontFamily: FontFamily.Regular,
                color: BaseColor.grey,
              }}
            >
              Stores
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            xl={12}
            ref={containerRef}
            style={{
              overflowX: "auto",
              marginBottom: "15px",
            }}
            className={
              isMobile || isTablet ? classes.scrollBarMain : classes.scrollBar
            }
            onMouseDown={!isMobile && !isTablet ? handleMouseDown : null}
            onMouseUp={!isMobile && !isTablet ? handleMouseUp : null}
            onMouseLeave={!isMobile && !isTablet ? handleMouseUp : null}
            onMouseMove={!isMobile && !isTablet ? handleMouseMove : null}
            onTouchStart={!isMobile && !isTablet ? handleTouchStart : null}
            onTouchEnd={!isMobile && !isTablet ? handleMouseUp : null}
            onTouchMove={!isMobile && !isTablet ? handleTouchMove : null}
          >
            <Grid
              container
              style={{
                flexWrap: "noWrap",
              }}
            >
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                xl={12}
                style={{
                  paddingBottom: 20,
                  paddingTop: 15,
                  display: "flex",
                }}
              >
                <Grid
                  container
                  gap={2}
                  style={{
                    flexWrap: "nowrap",
                    paddingLeft: "2px",
                    paddingRight: "2px",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    xl={4}
                    style={{ overflowY: "hidden" }}
                  >
                    <motion.div
                      style={iframeContainerStyle}
                      onClick={() => {
                        openInNewTab();
                      }}
                      initial={{ opacity: 0, y: 50 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 50 }}
                      transition={{ duration: 0.4 }}
                    >
                      <div
                        style={{
                          position: "relative",
                          width: "100%",
                          height: "300px",
                          cursor: "pointer",
                          border: "1px solid #ccc",
                          borderRadius: "10px",
                          overflow: "hidden",
                          width: sm ? "90vw" : md ? "41vw" : "27vw",
                          backgroundColor: "transparent",
                          zIndex: 100,
                        }}
                        // onClick={() => {
                        //   openInNewTab();
                        // }}
                      ></div>
                      <iframe
                        scrolling="no"
                        src={storeLink}
                        title="Free Family Website"
                        style={iframeStyle}
                      ></iframe>
                    </motion.div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <ReservationModel
            reservationModal={showReservationModel}
            setReservationModal={setShowReservationModel}
            editData2={showReservationModel?.data}
            getReservationListData={() => {
              getReservationList(
                !_.isNull(selectedMonth)
                  ? selectedMonth.toISOString().slice(0, 7)
                  : ""
              );
            }}
            selectedMembers={selectedMembers}
            setSelectedMembers={setSelectedMembers}
            isHome
          />
          {isCalendarOpen && (
            <CModal
              visible={calendarModal}
              onClose={() => {
                setCalendarModal(false);
              }}
              children={
                <Grid
                  container
                  padding={isMobile ? "20px" : "40px"}
                  style={{
                    position: "relative",
                    width: mobileScreen ? "90vw" : "440px",
                    maxHeight: "80vh",
                    overflowY: "auto",
                  }}
                >
                  <CloseIcon
                    onClick={() => {
                      setCalendarModal(false);
                    }}
                    className={classes.closeContainer}
                  />
                  <Grid
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{ width: "100%" }}
                  >
                    {/* <Calendar
                      onChange={setSelectedMonth}
                      value={selectedMonth}
                      onClickDay={(date) => {
                        handleDayClick(date);
                      }}
                      tileDisabled={isDateDisabled}
                      navigationLabel={({ date }) => (
                        <span
                          className={`${classes.customNavigationLabelText}`}
                        >
                          {date.toLocaleDateString("en-US", {
                            year: "numeric",
                          }) +
                            "  " +
                            date.toLocaleDateString("en-US", {
                              month: "short",
                            })}
                        </span>
                      )}
                      tileClassName={getTileClassName}
                      prev2Label={null}
                      next2Label={null}
                      className={`${classes.calendarText} ${classes.calendarWithoutBorder} ${classes.customNavigationLabel}`} // Apply the styles
                    /> */}
                    <img
                      src={selectedCalendarData?.club_image}
                      alt="contact us"
                      draggable={false}
                      style={{
                        objectFit: "contain",
                        width: md || isMobile || isTablet ? "100%" : "90%",
                        height: "80%",
                        borderRadius: 10,
                        userSelect: "none",
                      }}
                    />
                  </Grid>
                  {!_.isUndefined(selectedCalendarData) && (
                    <>
                      <hr className={classes.divider} />
                      <Grid
                        container
                        style={{
                          alignItems: "flex-start",
                          justifyContent: "space-between",
                          display: "flex",
                          flexWrap: "nowrap",
                          gap: "16px",
                        }}
                      >
                        <Grid item>
                          <Typography
                            style={{
                              fontFamily: FontFamily.SemiBold,
                              fontSize: "14px",
                              color: BaseColor.textColor,
                            }}
                          >
                            Date
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            style={{
                              fontFamily: FontFamily.SemiBold,
                              fontSize: "14px",
                              color: BaseColor.textColor,
                              textAlign: "end",
                            }}
                          >
                            {`${formatedselectedCalendarTime}, `}
                            {outputString}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        style={{
                          alignItems: "flex-start",
                          justifyContent: "space-between",
                          display: "flex",
                          flexWrap: "nowrap",
                          gap: "16px",
                          marginBottom: 15,
                          marginTop: 15,
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: FontFamily.SemiBold,
                            fontSize: "14px",
                            color: BaseColor.textColor,
                          }}
                        >
                          {selectedCalendarData?.self_reservation === 1
                            ? "Reserved by"
                            : "Hosting Member"}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            alt="userImage"
                            src={selectedCalendarData?.profile_pic_url}
                            style={{
                              height: "32px",
                              width: "32px",
                              borderRadius: "12px",
                            }}
                            draggable={false}
                          />
                          <Typography
                            style={{
                              fontFamily: FontFamily.SemiBold,
                              fontSize: "14px",
                              color: BaseColor.textColor,
                              paddingLeft: "10px",
                            }}
                          >
                            {selectedCalendarData?.self_reservation === 1
                              ? "You"
                              : selectedCalendarData?.host_name}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid
                        container
                        style={{
                          alignItems: "flex-start",
                          justifyContent: "space-between",
                          display: "flex",
                          flexWrap: "nowrap",
                          gap: "16px",
                        }}
                      >
                        <Grid item>
                          <Typography
                            style={{
                              fontFamily: FontFamily.SemiBold,
                              fontSize: "14px",
                              color: BaseColor.textColor,
                            }}
                          >
                            Members
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          style={{
                            alignItems: "flex-end",
                            justifyContent: "flex-start",
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          {selectedCalendarData?.reservation_member_data?.map(
                            (value, index) => (
                              <Grid key={index} item>
                                <div className={classes.memberContainer}>
                                  <Grid onClick={() => {}}>
                                    <div
                                      style={{
                                        position: "relative",
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Badge
                                        key={value.id}
                                        badgeContent={
                                          value?.guest_cnt == 0
                                            ? null
                                            : value?.guest_cnt
                                        }
                                        color="primary"
                                        overlap="circular"
                                        anchorOrigin={{
                                          vertical: "top",
                                          horizontal: "right",
                                        }}
                                      >
                                        <img
                                          src={
                                            value.profile_pic_url ||
                                            "https://golf.teamgroovy.com/img_assets/boy.jpeg"
                                          }
                                          alt="memberUserImage"
                                          style={{
                                            height: 40,
                                            width: 40,
                                            borderRadius: 20,
                                            marginBottom: 10,
                                          }}
                                          draggable={false}
                                        />
                                      </Badge>
                                      <div
                                        style={{
                                          background: value?.isSelected
                                            ? "#042B4860"
                                            : "none",
                                          height: "65px",
                                          width: "65px",
                                          borderRadius: "50%",
                                          position: "absolute",
                                          bottom: "3px",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          display: "flex",
                                        }}
                                      >
                                        {value?.isSelected && (
                                          <IoCheckmarkOutline
                                            color={BaseColor.white}
                                            size={40}
                                          />
                                        )}
                                      </div>
                                    </div>
                                    <Typography
                                      style={{
                                        fontSize: "12px",
                                        fontFamily: FontFamily.Bold,
                                        color: BaseColor.textColor,
                                        textAlign: "center",
                                        wordBreak: "break-word",
                                      }}
                                    >
                                      {value?.username}
                                    </Typography>
                                  </Grid>
                                </div>
                              </Grid>
                            )
                          )}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        style={{
                          paddingBottom: 10,
                          alignItems: "flex-start",
                          justifyContent: "space-between",
                          display: "flex",
                          flexWrap: "nowrap",
                          gap: "16px",
                        }}
                      >
                        <Grid item>
                          <Typography
                            style={{
                              fontFamily: FontFamily.SemiBold,
                              fontSize: "14px",
                              color: BaseColor.textColor,
                            }}
                          >
                            Club name
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          style={{
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                            display: "flex",
                          }}
                        >
                          <Typography
                            style={{
                              fontFamily: FontFamily.SemiBold,
                              fontSize: "14px",
                              color: BaseColor.textColor,
                              textAlign: "end",
                            }}
                          >
                            {selectedCalendarData?.club_name}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        style={{
                          alignItems: "flex-start",
                          justifyContent: "space-between",
                          display: "flex",
                          flexWrap: "nowrap",
                          gap: "16px",
                        }}
                      >
                        <Grid item>
                          <Typography
                            style={{
                              fontFamily: FontFamily.SemiBold,
                              fontSize: "14px",
                              color: BaseColor.textColor,
                            }}
                          >
                            Location
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          style={{
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                            display: "flex",
                          }}
                        >
                          <Typography
                            style={{
                              fontFamily: FontFamily.SemiBold,
                              fontSize: "14px",
                              color: BaseColor.textColor,
                              textAlign: "end",
                            }}
                          >
                            {selectedCalendarData?.club_location}
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              }
            />
          )}
        </>
      )}
      <CModal
        visible={locationPermissionModal}
        onClose={() => {
          dispatch(
            setLatLng({
              lat: 39.8283,
              lng: -98.5795,
            })
          );
          getClubList(39.8283, -98.5795, false, 4);
          setLocationPermissionModal(false);
          setZoom(4);
        }}
        children={
          <Grid
            container
            padding={isMobile ? "20px" : "40px"}
            style={{
              position: "relative",
              width: isTablet
                ? "75vw"
                : isMobile
                ? "90vw"
                : md
                ? "90vw"
                : "600px",
              maxHeight: "80vh",
              overflowY: "auto",
            }}
            className={classes.scrollBar}
          >
            <CloseIcon
              onClick={() => {
                dispatch(
                  setLatLng({
                    lat: 39.8283,
                    lng: -98.5795,
                  })
                );
                getClubList(39.8283, -98.5795, false, 4);
                setLocationPermissionModal(false);
                setZoom(4);
              }}
              style={{
                position: "absolute",
                top: 10,
                right: 15,
                fontSize: 30,
                cursor: !isMobile && !isTablet && "pointer",
                color: BaseColor.primary,
                zIndex: 1,
              }}
            />
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Lottie
                animationData={Files.lottie.locationLottie}
                loop={true}
                style={{
                  height: "150px",
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography
                style={{
                  fontFamily: FontFamily.GilbertQualifiDemo,
                  fontSize: isMobile ? "20px" : "24px",
                  color: BaseColor.blackColor,
                }}
              >
                Enable location permission
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              mt={"20px"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography
                style={{
                  fontFamily: FontFamily.Medium,
                  color: BaseColor.blackColor,
                }}
              >
                Please enable location permission to continue
              </Typography>
            </Grid>
          </Grid>
        }
      />
    </Grid>
  );
};

export default HomeComponent;
